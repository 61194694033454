/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import { consentChangeMiddleware, extendOrRenewSessionMiddleware } from 'V2Src/Actions/Middleware';
import reducers from 'V2Src/Actions/Reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'TrackingTool',
    })
  : compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(consentChangeMiddleware, extendOrRenewSessionMiddleware))
);

export default store;
