/**
 * Tealium tools
 */
import { getDataApiDomain, readCookie } from 'V2Src/Utils';
import { BRAND_ORANGE, PAY_VOD } from 'V2Src/PrivacyManager/Constants';

window.utag_data = {};
window.utag_cfg_ovrd = {
  noview: true, // This flag disables the auto send when the script is loaded load because we need to wait for user consent
  nocookie: true,
};

const updateUtagData = (ttDataLayer) => {
  window.utag_data = {};
  window.utag_data = ttDataLayer.clone();

  // We need to add the didomiVendorsEnabled to the utag_data object every time we send a request
  if (window.utag_data && window.didomiState?.didomiVendorsEnabled) {
    window.utag_data.didomiVendorsEnabled = window.didomiState?.didomiVendorsEnabled;
  }

  if (window.utag) {
    // This means we can call the utag.view function.
    // If we can't, when the Tealium script loads, it will send everything in the utag_data's variable.
    window.utag.view(window.utag_data);
  } else {
    // If utag is not defined then we need to fire tags when the tealium script loads so we disable the no autosend flag
    window.utag_cfg_ovrd = { noview: false };
  }
};

// Tealium support events as a list but we need to map it to a particular field set in tealium EventStream
const addEvents = (ttDataLayer) => {
  if (ttDataLayer.get('events') && ttDataLayer.get('events') instanceof Array) {
    ttDataLayer.add('eventList', ttDataLayer.get('events'));
  }
};

export const sendTracking = async (store, ttDataLayer) => {
  const state = store.getState();
  window.utag_data = {};

  // We need to add the didomiVendorsEnabled to the utag_data object every time we send a request
  if (window.utag_data && window.didomiState?.didomiVendorsEnabled) {
    window.utag_data.didomiVendorsEnabled = window.didomiState?.didomiVendorsEnabled;
  }

  addEvents(ttDataLayer);

  ttDataLayer.add('tealium_event', 'view page'); // Default event name is a page view

  // Send analytics request
  await sendRequest(ttDataLayer);

  // Fire tags
  if (state.adsAllowed && state.config.tealiumKey) {
    // Tealium adds a lot of stuff and we don't want to mess with the dataLayer
    updateUtagData(ttDataLayer);
  }
};

const sendRequest = async (ttDataLayer) => {
  // For debugging purpose only, "Trace" is a feature from Tealium that filters events based on a trace id
  // Trace Id cookie is set by the Tealium's browser extension (but it could work with any string)
  const traceId = readCookie('trace_id');

  const event = {
    'tealium_account': 'canalplus',
    'tealium_profile': 'main',
    'tealium_visitor_id': ttDataLayer.get('device_key'),
    'tealium_trace_id': traceId,
    'tealium_datasource': '4qrdb9',
    'tealium_timestamp_epoch': Math.floor(Date.now() / 1000),
    ...ttDataLayer.clone(),
  };

  const store = window.ttStore;
  const state = store.getState();
  const domain = getDataApiDomain(state.config.brand);

  await fetch(`https://${domain}/event`, {
    method: 'POST',
    body: JSON.stringify(event),
  });
};

export const sendEvent = async (ttDataLayer, callback) => {
  ttDataLayer.add(
    'tealium_event',
    ttDataLayer
      .get('event_name')
      ?.toLowerCase() /* lowercase = TEMP fix, we need to agree on a convention and it's lower case in Tealium */
  );
  addEvents(ttDataLayer);

  if (ttDataLayer.get('event_name') === PAY_VOD) {
    updateUtagData(ttDataLayer);
  }

  await sendRequest(ttDataLayer);

  if (callback) {
    callback(); // Sadly no real callback after the utag link event
  }
};

export const addTealiumScript = (store) => {
  const state = store.getState();
  const isOrange = state?.config?.brand === BRAND_ORANGE;
  const baseUrl = 'https://tags.tiqcdn.com/utag/canalplus/';
  const orangeTealiumUrl = 'https://canal.vct.pgw.orange.fr/tealium/';

  // If we have an item DTMstaging in the sessionStorage,
  // We want to add staging scripts instead of production's
  // Build the final script url
  const src = `${!isOrange ? baseUrl : orangeTealiumUrl}${state.config.tealiumKey}/${state.config.env}/utag.js`;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = src;
  document.body.appendChild(script);
};
