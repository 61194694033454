export default class QueueManagerForLoadEvent {
  /**
   * Constructor - Initialize the TMS queue manager
   */
  constructor() {
    this.queue = [];
  }

  /**
   * Flush the array with the queue
   */
  resetQueue() {
    this.queue = [];
  }

  /**
   * A function can subscribe to the queue
   */
  addToQueue(fn) {
    if (fn) {
      this.queue.push(fn);

      // We call the function for each pageView sent before the callback was added
      if (trackingQueue && trackingQueue.length > 0) {
        trackingQueue.forEach(() => fn());
      }
    }
  }

  /**
   * A function can trigger the queued functions
   * Use a "setTimeout" to perform a "process.nextTick"
   */
  exec() {
    /* eslint-disable */
    setTimeout(
      function() {
        this.queue.forEach(function(fn) {
          fn();
        });
      }.bind(this)
    );
    /* eslint-enable */
  }
}
