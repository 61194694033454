import {
  BRAND_AFRIQUE,
  BRAND_CALEDONIE,
  BRAND_CANALBOX,
  BRAND_CARAIBES,
  BRAND_ETHIOPIA,
  BRAND_HAITI,
  BRAND_MADAGASCAR,
  BRAND_MAURICE,
  BRAND_MYANMAR,
  BRAND_MYCANAL,
  BRAND_ORANGE,
  BRAND_REUNION,
  BRAND_TIMVISION,
  BRAND_TNTSAT,
  BRAND_TVCARAIBES,
  DATA_API_DOMAIN,
  DATA_API_DOMAIN_ORANGE,
  DATA_API_DOMAIN_TIMVISION,
  DATA_API_DOMAIN_TV,
  DATA_DOMAIN,
  DATA_DOMAIN_AFRIQUE,
  DATA_DOMAIN_CALEDONIE,
  DATA_DOMAIN_CANALBOX,
  DATA_DOMAIN_CARAIBES,
  DATA_DOMAIN_ETHIOPIA,
  DATA_DOMAIN_HAITI,
  DATA_DOMAIN_MADAGASCAR,
  DATA_DOMAIN_MAURICE,
  DATA_DOMAIN_MYANMAR,
  DATA_DOMAIN_ORANGE,
  DATA_DOMAIN_REUNION,
  DATA_DOMAIN_TIMVISION,
  DATA_DOMAIN_TNTSAT,
  DATA_DOMAIN_TV,
  DATA_DOMAIN_TVCARAIBES,
  TV_BRANDS,
} from 'V2Src/PrivacyManager/Constants';

export const extractDeviceInfos = (value) => {
  const cookieSplit = value.split(':');
  const authenticatedFlag = cookieSplit.length > 1 && cookieSplit[1] ? cookieSplit[1] : '';
  const trackingKey = cookieSplit.length > 2 && cookieSplit[2] ? cookieSplit[2] : '';
  return { deviceId: cookieSplit[0], authenticatedFlag, trackingKey };
};

const generateRandomAlphaNum = () => {
  return Math.floor((1 + Math.random()) * Number.MAX_SAFE_INTEGER)
    .toString(16)
    .substring(2);
};

export const generateUID = () => {
  return `${new Date().getTime().toString()}-${generateRandomAlphaNum()}`;
};

export const isPassIncluded = () => {
  return (
    window.waitForPassJSON &&
    typeof window.waitForPassJSON === 'function' &&
    window.isPassForm !== true /* We need to exclude Pass form where the waitForPassJSON will never be called */
  );
};

export const isPassAuthenticated = () => {
  return (
    typeof passJSON !== 'undefined' &&
    passJSON &&
    ((passJSON.subscriberId && passJSON.subscriberId > 0) ||
      (passJSON.accountId && passJSON.accountId > 0) ||
      passJSON.isAuthenticated === true)
  );
};

export const isUserCentricConsentModeActivated = () => passJSON.askForConsent !== undefined;

// get the query of an url or path
export const getQueryParam = (param, pathParam) => {
  const qd = {};
  const path = pathParam || window.location.search;

  if (path)
    path
      .substr(1)
      .split('&')
      .forEach((item) => {
        const s = item.split('=');
        const k = s[0];
        const v = s[1] && decodeURIComponent(s[1]); // null-coalescing / short-circuit
        // (k in qd) ? qd[k].push(v) : qd[k] = [v]
        (qd[k] = qd[k] || []).push(v); // null-coalescing / short-circuit
      });
  return qd[param] ? qd[param][0] : '';
};

// Credit : https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export const getAllQueryParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};

/* Plugins from Adobe s.c_r() => don't try to debug this */
/* eslint-disable */
export const readCookie = (cookieName) => {
  cookieName = escape(cookieName);
  var b = ' ' + document.cookie,
    cookieFound = b.indexOf(' ' + cookieName + '='),
    f = 0 > cookieFound ? cookieFound : b.indexOf(';', cookieFound);
  cookieName = 0 > cookieFound ? '' : unescape(b.substring(cookieFound + 2 + cookieName.length, 0 > f ? b.length : f));
  return '[[B]]' != cookieName ? cookieName : '';
};
/* eslint-enable */

/*
 * Parameters : cookieName, value, duration [default: session]
 * Plugins from Adobe s.c_w() => don't try to debug this
 */
/* eslint-disable */
export const writeCookie = (c, b, d, e) => {
  var l = location.hostname.indexOf('.');
  var f = l >= 0 ? location.hostname.slice(l) : location.hostname, // we write cookie on the domain level
    g;
  b = '' + b;
  e = e ? ('' + e).toUpperCase() : '';
  d &&
    'SESSION' != e &&
    'NONE' != e &&
    ((g = '' != b ? parseInt(e ? e : 0) : -60)
      ? ((d = new Date()), d.setTime(d.getTime() + 1e3 * g))
      : 1 == d && ((d = new Date()), (g = d.getYear()), d.setYear(g + 5 + (1900 > g ? 1900 : 0))));
  return c && 'NONE' != e
    ? ((document.cookie =
        escape(c) +
        '=' +
        escape('' != b ? b : '[[B]]') +
        '; SameSite=None; secure; path=/;' +
        (d && 'SESSION' != e ? ' expires=' + d.toGMTString() + ';' : '') +
        (f ? ' domain=' + f + ';' : '')),
      readCookie(c) == b)
    : 0;
};
/* eslint-enable */

// Source : stackoverflow https://stackoverflow.com/questions/4373018/sort-array-of-numeric-alphabetical-elements-natural-sort
// The idea is to sort in natural way. Example: prop2 is before prop10
export const naturalSort = (as, bs) => {
  let a;
  let b;
  let a1;
  let b1;
  const rx = /(\d+)|(\D+)/g;
  const rd = /\d/;
  const rz = /^0/;

  if (typeof as === 'number' || typeof bs === 'number') {
    if (Number.isNaN(as)) {
      return 1;
    }
    if (Number.isNaN(bs)) {
      return -1;
    }
    return as - bs;
  }

  a = String(as).toLowerCase();
  b = String(bs).toLowerCase();

  if (a === b) {
    return 0;
  }

  if (!(rd.test(a) && rd.test(b))) {
    return a > b ? 1 : -1;
  }

  a = a.match(rx);
  b = b.match(rx);

  while (a.length && b.length) {
    a1 = a.shift();
    b1 = b.shift();
    if (a1 !== b1) {
      if (rd.test(a1) && rd.test(b1)) {
        return a1.replace(rz, '.0') - b1.replace(rz, '.0');
      } else {
        return a1 > b1 ? 1 : -1;
      }
    }
  }
  return a.length - b.length;
};

const accentMap = {
  'á': 'a',
  'à': 'a',
  'â': 'a',
  'é': 'e',
  'ê': 'e',
  'è': 'e',
  'ë': 'e',
  'î': 'i',
  'ï': 'i',
  'ô': 'o',
  'ù': 'u',
  'û': 'u',
  'ü': 'u',
  'ç': 'c',
  'œ': 'oe',
  'Á': 'A',
  'À': 'A',
  'Â': 'A',
  'É': 'E',
  'Ê': 'E',
  'È': 'E',
  'Ë': 'E',
  'Î': 'I',
  'Ï': 'I',
  'Ô': 'O',
  'Ù': 'U',
  'Û': 'U',
  'Ü': 'U',
  'Ç': 'C',
  'Œ': 'OE',
};

export const replaceDiacritics = (str) => {
  if (typeof str !== 'string') {
    return str;
  }

  let ret = '';

  for (let i = 0; i < str.length; i += 1) {
    ret += accentMap[str.charAt(i)] || str.charAt(i);
  }

  return ret;
};

/**
 * Get the top domain part of a hostname
 * Ex 1: hostname="www.canalplus.com" -> domain=".canalplus.com"
 * Ex 2: hostname="skelp.pl.canalplus.com" -> domain=".canalplus.com"
 * @param {string} hostName name of the host
 */
const getTopDomain = (hostName = '') => {
  const nameSplit = hostName.replace('www.', '').split('.');
  return `${nameSplit[nameSplit.length - 2]}.${nameSplit[nameSplit.length - 1]}`;
};

export const isThirdPartyWebsite = () => {
  const hostname = window?.location?.hostname;
  const domain = getTopDomain(hostname);
  const dataTopLevelDomain = getTopDomain(DATA_DOMAIN);

  return domain !== dataTopLevelDomain;
};

/**
 * Simple function to read the value of a given cookie
 * @param {string} cookiename The **name** of the cookie
 * @returns {string} The **value** of the cookie
 */
export const getCookie = (cookiename) => {
  const cookiestring = RegExp(`${cookiename}[^;]+`).exec(document.cookie);
  return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '');
};

/**
 * Simple function to write a cookie
 * @param {string} name The **name** of the cookie
 * @param {string} value The **value** of the cookie
 * @param {number} days The **duration** of the cookie, in days
 * @param {string} [domain] The **domain** of the cookie
 */
export const setCookie = (name, value, days, domain = '') => {
  let expires = '';
  const cookieDomain = domain !== '' ? `domain=${domain}; ` : '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()}; `;
  }

  document.cookie = `${name}=${value}; ${expires}${cookieDomain}path=/; SameSite=None; secure;`; // eslint-disable-line unicorn/no-document-cookie
};

/**
 * Simple funtion to clean some cookies
 * @param {string[]} cookies The **names** of the cookies to clean
 */
export const cleanCookies = (cookies = []) => {
  cookies.forEach((cookie) => {
    document.cookie = `${cookie}=; domain=canalplus.com; path=/; expires=Thu, 01-Jan-1970 00:00:01 GMT;`; // eslint-disable-line unicorn/no-document-cookie
  });
};

/**
 * Adds a specific class to some HTMLElements
 * @param {HTMLElement[]} elements The elements that will receive the class
 * @param {string} classToAdd The class to add
 */
export const addClass = (elements, classToAdd) => {
  elements.forEach((element) => {
    const elementClasses = element.getAttribute('class') || '';
    const classes = elementClasses.split(' ');

    if (classes.indexOf(classToAdd) === -1) {
      if (classes.length === 1 && classes[0] === '') {
        element.setAttribute('class', `${classToAdd}`);
      } else {
        element.setAttribute('class', `${elementClasses} ${classToAdd}`);
      }
    }
  });
};

/**
 * Remove a specific class from some HTMLElements
 * @param {HTMLElement[]} elements The elements from which the class will be removed
 * @param {string} classToRemove The class to remove
 */
export const removeClass = (elements, classToRemove) => {
  elements.forEach((element) => {
    element.setAttribute('class', element.getAttribute('class').replace(classToRemove, ''));
  });
};

/**
 * Returns the right iframe domain depending on the app
 * @param {string} brand Name of the app that instantiates the script
 */
export const getDataDomain = (brand) => {
  if (TV_BRANDS.includes(brand)) {
    return DATA_DOMAIN_TV;
  } else {
    switch (brand) {
      case BRAND_TIMVISION:
        return DATA_DOMAIN_TIMVISION;
      case BRAND_ORANGE:
        return DATA_DOMAIN_ORANGE;
      case BRAND_TNTSAT:
        return DATA_DOMAIN_TNTSAT;
      case BRAND_AFRIQUE:
        return DATA_DOMAIN_AFRIQUE;
      case BRAND_CALEDONIE:
        return DATA_DOMAIN_CALEDONIE;
      case BRAND_CANALBOX:
        return DATA_DOMAIN_CANALBOX;
      case BRAND_CARAIBES:
        return DATA_DOMAIN_CARAIBES;
      case BRAND_ETHIOPIA:
        return DATA_DOMAIN_ETHIOPIA;
      case BRAND_HAITI:
        return DATA_DOMAIN_HAITI;
      case BRAND_MADAGASCAR:
        return DATA_DOMAIN_MADAGASCAR;
      case BRAND_MAURICE:
        return DATA_DOMAIN_MAURICE;
      case BRAND_MYANMAR:
        return DATA_DOMAIN_MYANMAR;
      case BRAND_REUNION:
        return DATA_DOMAIN_REUNION;
      case BRAND_TVCARAIBES:
        return DATA_DOMAIN_TVCARAIBES;
      case BRAND_MYCANAL:
      default:
        return DATA_DOMAIN;
    }
  }
};

/**
 * Returns the right Tealium domain depending on the app
 * @param {string} brand Name of the app that instantiates the script
 */
export const getDataApiDomain = (brand) => {
  if (TV_BRANDS.includes(brand)) {
    return DATA_API_DOMAIN_TV;
  } else {
    switch (brand) {
      case BRAND_TIMVISION:
        return DATA_API_DOMAIN_TIMVISION;
      case BRAND_ORANGE:
        return DATA_API_DOMAIN_ORANGE;
      default:
        return DATA_API_DOMAIN;
    }
  }
};

/**
 * Get the `brand` query string value of an URL
 * @param {*} src The URL to search
 */
export const getBrandQueryString = (src) => {
  const queryStrings = src.substring(src.lastIndexOf('?') + 1);
  const params = new URLSearchParams(queryStrings);
  return params.get('brand');
};
